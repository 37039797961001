<template>
    <div class="app-header">
        <div
            class="
                header-wrap
                d-flex
                align-center
                px-4
                text-white
                p-relative
                mb-50
            "
        >
            <router-link :to="{ name: 'Home' }" class="header-logo mr-4">
                <img src="/img/logo_light.png" alt="Logo" class="d-block" />
            </router-link>
            <nav class="header-navigation f-fill mx-4">
                <ul class="d-flex" ref="listParent">
                    <li
                        class="
                            header-navigation__list
                            active
                            transition
                            homeCss
                        "
                    >
                        <div
                            class="
                                link-home
                                py-2
                                px-4
                                header-navigation__anchor
                                pointer
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <span class="text-color-s">
                              <!-- <router-link tag="a" to="/" :class="menu ? 'button' : 'buttonclose'" class="button__fly">
                  <span class="navigation__list" :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-chart-simple icon__fontawesome"></span></span>
                  <transition name="slide-fade">
                    <div v-if="menu" :class="mode ? 'text' : 'textnight'">Рабочий стол</div>
                  </transition> -->
                <!-- </router-link> -->
                                <router-link :to="{ name: 'Home' }"  class="button__fly">
                                <span class="header-icon navigation__list" style="position:relative; font-size: 22px;"   :class="[mode ? 'iconsday' : 'iconsnight']"><span  class=" fa-solid fa-chart-simple icon__fontawesome " style="position:relative; font-size: 20px; margin:0;width: auto;" ></span></span>
                                    <!-- <i
                                        class="
                                            
                                            header-icon
                                            d-block
                                            mb-1
                                            text-center
                                            fa-solid fa-user-plus
                                        "
                                    ></i> -->
                                    <span class="font_12">{{
                                        $t("message.desktop")
                                    }}</span>
                                </router-link>
                                
                            </span>
                        </div>
                    </li>

                    <li class="header-navigation__list transition">
                        <div
                            class="header-navigation__anchor button__fly button__fly"
                            v-can-or="[
                                'VacancyController@index',
                                'CandidateController@index',
                            ]"
                        >
                                <span class="header-icon navigation__list"    :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-user-plus icon__fontawesome " style="position:relative; font-size: 20px; margin:0; width: auto;" ></span></span>
                             
                            <span class="text-color-s font_12">{{
                                $t("message.recruiting")
                            }}</span>
                        </div>
                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                px-4
                                transition
                            "
                        >
                            <router-link
                                :to="{ name: 'vacancy' }"
                                v-can="'VacancyController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.vacancy")
                                }}</span>
                            </router-link>
                            <router-link
                                :to="{ name: 'candidates' }"
                                v-can="'CandidateController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.candidates")
                                }}</span>
                            </router-link>
                            <!-- <router-link
                                :to="{ name: 'testing' }"
                                class="dropdown-anchor py-3 px-2 mr-2 transition"
                            >
                                <span class="d-block">Тесты</span>
                            </router-link>
                            <router-link
                                :to="{ name: 'adaptation' }"
                                class="dropdown-anchor py-3 px-2 mr-2 transition"
                            >
                                <span class="d-block">Адаптация</span>
                            </router-link> -->
                        </div>
                    </li>

                    <li class="header-navigation__list transition">
                        <div
                            class="header-navigation__anchor button__fly"
                            v-can="'StaffController@index'"
                        >
                            <span class="header-icon navigation__list"    :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-user-group icon__fontawesome " style="position:relative; font-size: 20px; margin:0; width: auto;" ></span></span>

                            <span class="text-color-s font_12"
                                >{{ $t("message.employees") }}
                            </span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                px-4
                                transition
                            "
                        >
                            <router-link
                                :to="{ name: 'staff' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.staffTest") }}</span
                                >
                            </router-link>

                            <router-link
                                :to="{ name: 'current' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.operating")
                                }}</span>
                            </router-link>

                            <router-link
                                :to="{ name: 'dismissed' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.dismissed")
                                }}</span>
                            </router-link>
                        </div>
                    </li>

                    <li class="header-navigation__list transition">
                        <div
                            class="header-navigation__anchor button__fly"
                            v-can-or="[
                                'TransactionController@index',
                                'PenaltyController@getStaffPenalty',
                                'AccountController@index',
                                'BonusController@getStaffBonus',
                                'AskAssentController@advanceSalaries'
                                
                            ]"
                        >
                            <!-- <i
                                class="
                                    recruiting
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                            ></i> -->
                            <span class="header-icon navigation__list"    :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-sack-dollar icon__fontawesome " style="position:relative; font-size: 20px; margin:0; width: auto;" ></span></span>

                            <span class="text-color-s font_12">{{
                                $t("message.finance")
                            }}</span>
                        </div>
                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                px-4
                                transition
                            "
                        >
                            <router-link
                                :to="{ name: 'transaction' }"
                                v-can="'TransactionController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.o_salary") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'penalties' }"
                                v-can="'PenaltyController@getStaffPenalty'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.sanction") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'accounts' }"
                                v-can="'AccountController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.check_a") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'bonuses' }"
                                v-can="'BonusController@getStaffBonus'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.bonuses")
                                }}</span>
                            </router-link>
                             <router-link
                                v-can="'AskAssentController@advanceSalaries'"

                                :to="{ name: 'advanceSalary' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"> {{$t("message.avance")}} </span>
                            </router-link>
                        </div>
                    </li>

                    <li class="header-navigation__list transition">
                        <div class="header-navigation__anchor button__fly">
                            <span class="header-icon navigation__list"    :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-road-barrier icon__fontawesome " style="position:relative; font-size: 20px; margin:0; width: auto;" ></span></span>
                            <span class="text-color-s font_12">
                                {{ $t("message.KPP") }}
                            </span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                px-4
                                transition
                            "
                        >
                            <router-link
                                :to="{ name: 'visitor' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"
                                    >{{ $t("message.visitor") }}
                                </span>
                            </router-link>

                            <router-link
                                :to="{ name: 'guest_car' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"
                                    >{{ $t("message.card") }}
                                </span>
                            </router-link>

                            <router-link
                                :to="{ name: 'guest' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.guest") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'tms' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"> {{$t("message.tms")}}</span>
                            </router-link>
                            <router-link
                                :to="{ name: 'employee_kpp' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                     {{$t("message.employee_kpp")}}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'operation' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"> {{$t("message.operation")}} </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'service' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">   {{$t("message.company_machines")}}</span>
                            </router-link>
                        </div>
                    </li>

                    <li class="header-navigation__list transition">
                        <div
                            class="header-navigation__anchor button__fly"
                           v-can-or="[
                                'AttendanceController@index',
                                'StructureController@structure',
                                'AttendanceReportController@getReports',
                                'AssentController@takeAssent',
                                'AskAssentController@complaint',
                            ]"
                        >
                            <span class="header-icon navigation__list"    :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-chart-line icon__fontawesome " style="position:relative; font-size: 20px; margin:0; width: auto;" ></span></span>

                            <span class="text-color-s font_12">{{
                                $t("message.indicators")
                            }}</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                px-4
                                transition
                            "
                        >
                            <router-link
                             v-can="'AttendanceController@index'"
                                :to="{ name: 'journal' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.journal")
                                }}</span>
                            </router-link>

                            <router-link
                                :to="{ name: 'structure' }"
                                v-can="'StructureController@structure'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{$t("message.structure")}} </span>
                            </router-link>

                            <router-link
                                :to="{ name: 'monitoring' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.nv_monitoring")
                                }}</span>
                            </router-link>

                            <router-link
                                :to="{ name: 'wage' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">З/П</span>
                            </router-link>

                            <router-link
                                v-can="'AttendanceReportController@getReports'"
                                :to="{ name: 'attendance_report' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{$t("message.report_card")}} </span>
                            </router-link>

                            <router-link
                                v-can="'AssentController@takeAssent'"
                                :to="{ name: 'takeAssent' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{$t("message.leave_requests")}} </span>
                            </router-link>

                           

                            <router-link
                                v-can="'AskAssentController@complaint'"
                                :to="{ name: 'complaint' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{$t("message.complaint")}}</span>
                            </router-link>

                            <router-link
                                v-can="'AttendanceReportDepartmentController@getReports'"

                                :to="{ name: 'attendance' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{$t("message.attendance")}}</span>
                            </router-link>
                             <router-link
                                

                                :to="{ name: 'additionalWork' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"> {{$t("message.additional_work")}}</span>
                            </router-link>  
                            <router-link
                                

                            :to="{ name: 'onlineWork' }"
                              class="
                                  dropdown-anchor
                                  py-3
                                  px-2
                                  mr-2
                                  transition
                              "
                          >
                        <span class="d-block"> {{$t("message.online_works")}}</span>
                    </router-link>
                        </div>
                    </li>

                    <!-- <li class="header-navigation__list transition">
                        <div class="header-navigation__anchor button__fly">
                            <i
                                class="
                                    add-user
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                            ></i>
                            <span class="text-color-s font_12">Документы</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                px-4
                                transition
                            "
                        >
                            <router-link
                                :to="{ name: 'contract' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">Договора</span>
                            </router-link>
                        </div>
                    </li> -->

                    <li class="header-navigation__list transition">
                        <div
                            class="header-navigation__anchor button__fly"
                            v-can-or="[
                                'UserController@index',
                                'RoleController@index',
                                'PermissionController@index',
                            ]"
                        >
                            <span class="header-icon navigation__list"    :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-user-gear icon__fontawesome " style="position:relative; font-size: 20px; margin:0; width: auto;" ></span></span>

                            <span class="text-color-s font_12">{{$t('message.access_control')}}</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                px-4
                                transition
                            "
                        >
                            <router-link
                                :to="{ name: 'users' }"
                                v-can="'UserController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"
                                    >{{ $t("message.users") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'roles' }"
                                v-can="'RoleController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"
                                    >{{ $t("message.role") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'permissions' }"
                                v-can="'PermissionController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"
                                    >{{ $t("message.permission") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'user_activity' }"
                                v-can="'UserActivityController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"
                                    >{{ $t("message.user_activity") }}
                                </span>
                            </router-link>
                        </div>
                    </li>

                    <li class="header-navigation__list transition">
                        <div class="header-navigation__anchor button__fly">
                             <span class="header-icon navigation__list"    :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-gear icon__fontawesome " style="position:relative; font-size: 20px; margin:0; width: auto;" ></span></span>

                            <!-- <font-awesome-icon class=" header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s" icon="fa-solid fa-user-secret" /> -->
                            <span class="text-color-s font_12">{{
                                $t("message.settings")
                            }}</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                px-4
                                transition
                            "
                            style="overflow:auto"
                        >
                        <div style="display:inherit">
                            <router-link
                                :to="{ name: 'graphicType' }"
                                v-can="'GraphicTypeController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"
                                    >{{ $t("message.graphicType") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'graphic' }"
                                v-can="'GraphicController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"
                                    >{{ $t("message.graphic") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'department' }"
                                v-can="'DepartmentController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.department_title") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'branch' }"
                                v-can="'BranchController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.branch") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'company' }"
                                v-can="'CompanyController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.company") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'position' }"
                                v-can="'PositionController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.position")
                                }}</span>
                            </router-link>
                            <router-link
                                :to="{ name: 'priority' }"
                                v-can="'PriorityController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.priority") }}
                                </span>
                            </router-link>

                            <router-link
                                :to="{ name: 'region' }"
                                v-can="'RegionController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.region") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'district' }"
                                v-can="'DistrictController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.district") }}
                                </span>
                            </router-link>

                            <router-link
                                :to="{ name: 'reason' }"
                                v-can="'ReasonController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.reason") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'reasonType' }"
                                v-can="'ReasonTypeController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.reasontype") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'requirements' }"
                                v-can="'RequirementController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.requirements") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'language' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.language2") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'currency' }"
                                v-can="'CurrencyController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.currency") }}
                                </span>
                            </router-link>
                            <!-- <router-link
                                :to="{ name: 'salaryCurrency' }"
                                class="dropdown-anchor py-3 px-2 mr-2 transition"
                            >
                                <span class="d-block"> Валюта З/П  </span>
                            </router-link> -->
                            <router-link
                                :to="{ name: 'skill' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.skill") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'nation' }"
                                v-can="'NationController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.nation") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'social' }"
                                v-can="'NationController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.social") }}
                                </span>
                            </router-link>
                            <router-link
                                :to="{ name: 'education' }"
                                v-can="'NationController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.education") }}
                                </span>
                            </router-link>

                            <router-link
                                          :to="{ name: 'staffWorkType' }"
                                          v-can="'StaffWorkTypeController@index'"
                                          class="
                                              dropdown-anchor
                                              py-3
                                              px-2
                                              mr-2
                                              transition
                                          "
                                          style="display: block;"
                                      >
                                          <span class="d-block">
                                              {{ $t("message.staff_work_type") }}
                                          </span>
                                      </router-link>
                             <router-link
                                        :to="{ name: 'staffAssentReason' }"
                                        v-can="'ReasonController@index'"
                                        class="
                                            dropdown-anchor
                                            py-3
                                            px-2
                                            mr-2
                                            transition
                                        "
                                        style="display: block;"
                                    >
                                        <span class="d-block">
                                            {{ $t("message.staffAssentReason") }}
                                        </span>
                                    </router-link>
                                     <router-link
                                        :to="{ name: 'category' }"
                                        v-can="'CategoryController@index'"
                                        class="
                                            dropdown-anchor
                                            py-3
                                            px-2
                                            mr-2
                                            transition
                                        "
                                        style="display: block;"
                                    >
                                        <span class="d-block">
                                            {{ $t("message.category") }}
                                        </span>
                                    </router-link>
                                      <router-link
                                        :to="{ name: 'telegramManager' }"
                                        v-can="'TelegramManagerController@index'"
                                        class="
                                            dropdown-anchor
                                            py-3
                                            px-2
                                            mr-2
                                            transition
                                        "
                                        style="display: block;"
                                    >
                                        <span class="d-block">
                                            {{ $t("message.telegram_manager") }}
                                        </span>
                                    </router-link>

                        </div>
                        </div>
                    </li>

                    <li class="header-navigation__list transition">
                        <div
                            class="header-navigation__anchor button__fly"
                            v-can-or="[
                                'SecurityLocationController@index',
                                'DeviceTypeController@index',
                                'DeviceController@index',
                            ]"
                        >
                            <span class="header-icon navigation__list"    :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-microchip icon__fontawesome " style="position:relative; font-size: 20px; margin:0; width: auto;" ></span></span>
                            <span class="text-color-s font_12">ACMS</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                px-4
                                transition
                            "
                        >
                            <router-link
                                :to="{ name: 'securityLocation' }"
                                v-can="'SecurityLocationController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.security_location")
                                }}</span>
                            </router-link>
                            <router-link
                                :to="{ name: 'deviceType' }"
                                v-can="'DeviceTypeController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.deviceType")
                                }}</span>
                            </router-link>
                            <router-link
                                :to="{ name: 'devices' }"
                                v-can="'DeviceController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.device") }}
                                </span>
                            </router-link>
                            <!-- <router-link
                                :to="{ name: 'synchronization' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"> Синхронизация </span>
                            </router-link> -->
                            <!-- <router-link
                                :to="{ name: 'applicationCandidates' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"> test </span>
                            </router-link> -->
                        </div>
                    </li>

                    <li class="header-navigation__list transition">
                        <div
                            class="header-navigation__anchor button__fly"
                            v-can-or="[
                                'CalendarController@index',
                            ]"
                        >
                            <!-- <i
                                class="
                                    el-icon-date
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                                style="font-size: 18px"
                            ></i> -->
                            <span class="header-icon navigation__list"    :class="[mode ? 'iconsday' : 'iconsnight']"><span  class="fa-solid fa-calendar-days icon__fontawesome " style="position:relative; font-size: 20px; margin:0; width: auto;" ></span></span>
                            <span class="text-color-s font_12">{{$t("message.calendar")}}</span>
                        </div>
                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                px-4
                                transition
                            "
                        >
                            <router-link
                                :to="{ name: 'calendar' }"
                                v-can="'CalendarController@index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"> {{$t("message.calendar")}} </span>
                            </router-link>
                        </div>
                    </li>
                </ul>
            </nav>
            <div>
                <el-dropdown class="flag"  trigger="click" @command="setLanguage">
                    <span class="el-dropdown-link">
                        <!-- <img src="/img/flag/russia.svg" alt class="flag_img" /> -->
                        <img
                        v-if="this.$i18n.locale == 'uz'"
                        src="/img/flag/uzbekistan.svg"
                        alt
                        class="flag_img"
                    />
                    <img
                        v-if="this.$i18n.locale == 'ru'"
                        src="/img/flag/russia.svg"
                        alt
                        class="flag_img"
                    />
                    <img
                        v-if="this.$i18n.locale == 'en'"
                        src="/img/flag/english.svg"
                        alt
                        class="flag_img"
                    />
                    <img
                        v-if="this.$i18n.locale == 'tr'"
                        src="/img/flag/turkey.svg"
                        alt
                        class="flag_img"
                    />
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu   slot="dropdown" class="flag_ul">
                      <el-dropdown-item command="tr" >
                        <img
                            src="/img/flag/turkey.svg"
                            alt
                            class="flag_img flag_img2"
                        />
                        <span>Turkey</span>
                      </el-dropdown-item>
                      <el-dropdown-item  command="uz" >
                          <img
                              src="/img/flag/uzbekistan.svg"
                              alt
                              class="flag_img flag_img2"
                          />
                          <span>Uzbek</span>
                      </el-dropdown-item>
                        <el-dropdown-item command="ru">
                            <img 
                                src="/img/flag/russia.svg"
                                alt
                                class="flag_img flag_img2"
                            />
                            <span>Russia</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="user-info-header">
                <el-dropdown>
                    <span class="el-dropdown-link">
                        <img src="/img/flag/employee.svg" alt="" />
                    </span>
                    <el-dropdown-menu slot="dropdown" class="f-info">
                        <el-dropdown-item class="my-p name-tell">
                            <div class="user-name">
                                {{ user.name + " " + user.last_name }}
                            </div>
                            <div class="user-tel">+{{ user.phone_number }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item class="my-p">
                            <router-link :to="{ name: '/' }">
                                <i class="el-icon-user"></i>
                                Профиль
                            </router-link>
                        </el-dropdown-item>
                        <el-dropdown-item class="my-p">
                            <a @click.prevent="logout">
                                <i class="el-icon-lock"></i>
                                {{ $t("message.quit") }}
                            </a>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { tabSwitch } from "@/utils/tabSwitch.js";
import { removeToken } from "@/utils/auth";
import { i18n } from "@/utils/i18n";

export default {
    components: {},
    data() {
        return {
            staffOnOff: false,
            system_language: localStorage.getItem('sys_language') ? localStorage.getItem('sys_language') : 'ru',
        };
    },
    mounted() {
        const { listParent } = this.$refs;
        tabSwitch(listParent);
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            slug: "auth/slug",
            mode:'MODE'
        }),
    },
    methods: {
        ...mapActions({
            userLogOut: "auth/logout",
        }),

        setLanguage(val) {
          localStorage.setItem('sys_language', val);
            this.$i18n.locale = val;
        },
        logout() {
            this.$confirm(
                i18n.t('message.logout_question'),
                i18n.t('message.login'),
                {
                    confirmButtonText: i18n.t('message.yes'),
                    cancelButtonText: i18n.t('message.no'),
                    type: "warning",
                }
            )
                .then(() => {
                    this.userLogOut()
                        .then(() => {})
                        .catch((error) => {
                            window.location.reload();
                            reject(error);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: "Операция отменена",
                    });
                });
        },
    },
};
</script>

<style lang="scss">
.app-header {
    position: relative;
    z-index: 2;
    .header-navigation__list--dropdown {

      &::-webkit-scrollbar {
          height: 6px !important;
          background: rgb(151, 151, 151); 
          }
          &::-webkit-scrollbar-track {
            background: #2e2e2e42; 
          }
          &::-webkit-scrollbar-thumb {
          background: rgb(240, 240, 240); 
          border-radius: 10px;
        }
    } 

    .dropdown-anchor {
        white-space: nowrap;
    }

}

.link-home {
    min-height: 50px;
    display: inline-block;
    font-size: 0.825rem;
}

.link-home a {
    color: #fff;
}

.flag {
    margin-top: 10px;
    margin-right: 10px;

    .el-dropdown-link {
        border: 1px solid #ffffff4a;
        padding: 0px 4px !important;
        border-radius: 4px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .flag_img {
        width: 30px;
    }

    .el-icon--right {
        margin-left: 3px;
        font-size: 10px;
        color: #fff;
        margin-right: -2px;
    }
}

.flag_img2 {
    width: 31px;
}

.flag_ul {
    .el-dropdown-menu__item {
        border-bottom: 1px solid #f1f5f8;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-dropdown-menu__item img {
        margin-right: 5px;
    }
}

.flag_ul li:nth-child(2n + 2) {
    border-bottom: none;
}

.user-info-header {
    .el-dropdown-link img {
        width: 33px;
        height: 31px;
        margin-top: 7px;
    }
}

.my-p a {
    color: #326889;
}

.name-tell {
    line-height: 25px;
    border-bottom: 1px solid #a3a3a35c;
    font-size: 13px;
}
</style>
